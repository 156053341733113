import React, {
    useState, useEffect, useContext
} from 'react'
import { Div, FieldError } from '../../hybrid/wrappers'
import Cart from './../cart/Cart'
import CartFilterItem from './CarfFilterItem';
import Styles from '../../common/decorators/Styles';
import getStyles from '../../project/styles/widget-styles/cart-filter-styles';
import PropTypes from 'prop-types';
import { LangContext } from '../../common/decorators/Language';
import { getData } from '../../common/utils/router';
import UserContext from '../../common/utils/getContext';

const CartFilter = ({
    styles,
    errors,
    cartData,
    funcs,
    storeAlias
}) => {
    const { translation } = useContext(LangContext) || {};
    const { userToken, lang } = useContext(UserContext);
    const [errorData, setErrorData] = useState(errors)
    const [domain, setDomain] = useState(errors)

    useEffect(() => {
        (async () => {
            if (storeAlias) {
                const getDomain = await getData(`/api/v1/admin/shop/shop/${storeAlias}/only-domain`, userToken, lang);
                if (getDomain?.data?.domain?.status === 'active') {
                    setDomain(getDomain?.data?.domain)
                }
            }
        })();
    }, [userToken, lang, storeAlias])

    useEffect(() => {
        if (errors && errors.length > 0) {
            setErrorData(errors)
        }
    }, [errors])
    return (
        <Div styles={styles.cartFilterWrapper}>
            <CartFilterItem
                styles={styles}
                cartData={cartData}
                funcs={funcs}
                setErrorData={setErrorData}
                translation={translation}
                storeAlias={storeAlias}
            />
            <Cart
                products={cartData}
                type='header-general'
                funcs={funcs}
                page={'/admin/store/order'}
                domain={domain}
            />
            {errorData && errorData.map((err, idx) =>
                <FieldError styles={styles} key={`errCart${idx}`}>{err}</FieldError>)}
        </Div>
    )
}

CartFilter.propTypes = {
    styles: PropTypes.object,
    errors: PropTypes.array,
    cartData: PropTypes.array,
    funcs: PropTypes.object,
    translation: PropTypes.object
};
export default Styles(CartFilter, getStyles)