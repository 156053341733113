import React, { useContext } from 'react'
import { dataSender } from '../../common/utils/router';
import {
    Div, svgSprite, Img
} from '../../hybrid/wrappers'
import CartCounter from './CartCounter';
import UserContext, { StoreContext } from '../../common/utils/getContext'
import { navigate } from './../../common/utils/router';
import T from '../../common/components/T';
import { API_SERVER_URL } from '../../common/utils/utils';
import { useRouter } from 'next/router';

const CartItem = ({
    item,
    styles,
    price = false,
    article = true,
    funcs,
    page,
    updateDelivery,
    setUpdateDelivery,
    domain
}) => {
    const router = useRouter()
    const {
        userToken, lang, setAlertData, localization
    } = useContext(UserContext)
    const { cartData, setCartData } = useContext(StoreContext) || {};
    const deleteProduct = async () => {
        if (cartData.id){
            dataSender(
                `${API_SERVER_URL}/api/v1/shop/carts/${cartData.id}/delete-product?_method=DELETE`,
                'POST',
                { product_id: item.product.id },
                (response) => {
                    setCartData(response.data?.data);
                    setUpdateDelivery(!updateDelivery);
                    if ((router.asPath === `/${lang}/store/cart` || router.asPath === '/store/cart') && !response?.data?.data?.items?.length){
                        navigate('/[lang]/store', '/store', lang);
                    }
                },
                () => {
                    setAlertData({
                        type: 'error', cont: true, default: 'error_delete_data'
                    })
                },
                userToken,
                lang,
                localization?.currency
            );
        }
    }
    const handleLink = () => {
        if (domain){
            window.open(`${domain?.name}/${lang}/store/product/${item.product.id}`, '_blank')
        } else {
            window.open(`/${lang}/store/product/${item.product.id}`, '_blank')
        }
    }

    return (
        <Div styles={item?.status === 'unavailable' ? { ...styles.cartWrapperItem, 'background': styles.variable.redDarkExtraTransparent } : styles.cartWrapperItem}>
            <Div styles={styles.cartWrapperLeft}>
                <Div styles={styles.cartItemImage}>
                    {
                        !!item?.product?.image ? <Img styles={styles.imageGridItem} src={item?.product?.image?.links?.thumb} alt='image'/>
                            : svgSprite('plug-grid-image', { width: 48, height: 48 })
                    }
                </Div>
            </Div>
            <Div styles={funcs ?
                { ...styles.cartWrapperRight, ...styles.cartWrapperRightHeader } :
                styles.cartWrapperRight}>
                <Div styles={funcs ? { ...styles.cartItemName, ...styles.cartItemNameHeader } : styles.cartItemName}>
                    {item?.status === 'unavailable' &&
                        <Div styles={styles.cartItemUnavailable}><T textName='storeUnavailableItem' defaultStr='Товар не доступен' page={page || '/store'} /></Div>
                    }
                    <Div
                        data-uitest='539_uitest' onClick={handleLink}
                        styles={styles.cartItemTitle}
                        effects={{ hover: { ...styles.cartItemTitleHover } }}
                    >{item.product.name}</Div>
                    {article && <Div styles={styles.cartItemArticle}>
                        <Div styles={styles.cartItemArticleTitle}><T textName='storeArticle' defaultStr='Артикул' page={page || '/store'} />:</Div>
                        <Div>{item.product.article}</Div>
                    </Div>}
                    {item.product?.is_preorder && <Div styles={styles.cartItemPreOrder}><T textName='storeCartPreorder' defaultStr='Предзаказ' page={page || '/store'} /></Div>}
                </Div>
                <Div styles={{ display: 'flex', 'align-items': 'center' }}>
                    {price && <Div styles={styles.totalPriceModern}>{item.currency_symbol} {item.price?.toFixed(2)}</Div>}
                    <CartCounter
                        styles={styles}
                        count={item.quantity}
                        idProduct={item.product.id}
                        funcs={funcs}
                        price={price}
                        updateDelivery={updateDelivery}
                        setUpdateDelivery={setUpdateDelivery}
                        blockNum={item?.product?.quantity_of_goods_in_one_hand_for_all_time && item?.product?.available_to_buy ? item?.product?.available_to_buy : item?.product?.quantity_of_goods_in_one_hand_for_all_time }
                    />
                    {price ? <Div styles={{ ...styles.cartItemPrice, ...styles.cartItemPriceGrid }}>
                        {item?.product?.is_preorder && item?.product?.price?.preorder_price_formatted ?
                            <Div styles={styles.totalPrice}>{item?.product?.price?.preorder_price_formatted}</Div> :
                            <Div styles={styles.totalPrice}>{item.currency_symbol} {(item.price * item.quantity).toFixed(2)}</Div>
                        }

                        <Div styles={styles.totalMarketingPrice}>{item.marketing_currency_symbol} {(item.marketing_price * item.quantity).toFixed(2)}</Div>
                    </Div> : <Div styles={styles.cartItemPrice}>
                        {item?.product?.is_preorder && item?.product?.price?.preorder_price_formatted ?
                            <Div styles={styles.totalPrice}>{item?.product?.price?.preorder_price_formatted}</Div> :
                            <Div styles={styles.totalPrice}>{item.price_formatted}</Div>
                        }

                        <Div styles={styles.totalMarketingPrice}>{item.marketing_price_formatted}</Div>
                    </Div>}
                </Div>
            </Div>
            {/* <Div data-uitest='540_uitest' onClick={() => deleteProduct()} styles={styles.cartItemDeleteIcon}>
                    {svgSprite('trash')}
                </Div> */}
            <Div
                onClick={funcs && funcs.del ? () => funcs.del(item.product.id) : () => deleteProduct()}
                effects={{ hover: styles.cartItemDeleteIconHover }}
                styles={styles.cartItemDeleteIconWrap}
            >
                {svgSprite('trash', { style: styles.cartItemDeleteIcon })}
            </Div>

        </Div>
    )
}

export default CartItem