import React, {
    useContext,
    useCallback
} from 'react';
import CartItem from './CartItem';
import Styles from '../../common/decorators/Styles';
import getStyles from '../../project/styles/widget-styles/cart-styles';
import { Div } from '../../hybrid/wrappers';
import CartItemWidget from './CartItemWidget';
import {
    navigate,
    dataSender
} from './../../common/utils/router';
import UserContext, { StoreContext } from '../../common/utils/getContext';
import { declOfNum } from './../../common/utils/utils';
import T from '../../common/components/T';
import { API_SERVER_URL } from '../../common/utils/utils';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import PackProgress from '../../views/store/packProgress/PackProgress';
import { useStorePackStats } from '../../common/utils/useStorePackStat';

const Cart = ({
    products,
    styles,
    type = 'general',
    price,
    totalProductsPrice,
    price_marketing = 'PV 0',
    funcs,
    page,
    updateDelivery,
    setUpdateDelivery,
    domain
}) => {
    const {
        userToken, lang, setAlertData, localization
    } = useContext(UserContext);
    const {
        cartData, setCartData, setModalCartShow
    } = useContext(StoreContext) || {};

    const {
        currentUserPack, lastPack, packStat
    } = useStorePackStats() || {};
    const router = useRouter();
    const cartSumPlusAccumulated = packStat?.current?.id < 3
        ? cartData?.total_marketing_price + packStat?.current?.user_total_personal_volume
        : cartData?.total_marketing_price

    const handleClearPrevPayment = useCallback(() => {
        if (localStorage.getItem('finish')) {
            let item = localStorage.getItem('finish');
            if (JSON.parse(item)?.selectedPay?.alias === 'paymentkaspi' || JSON.parse(item)?.data?.qr_token) {
                localStorage.removeItem('finish');
            }
        } else if (localStorage.getItem('answerAfterCheckout')) {
            let item = localStorage.getItem('answerAfterCheckout');
            if (JSON.parse(item)?.selectedPayment?.alias === 'paymentkaspi' || JSON.parse(item)?.data?.qr_token) {
                localStorage.removeItem('answerAfterCheckout');
            }
        }
    }, []);

    const deleteProducts = async () => {
        if (cartData.id) {
            dataSender(
                `${API_SERVER_URL}/api/v1/shop/carts/${cartData.id}/clear?_method=DELETE`,
                'DELETE',
                false,
                (response) => {
                    setCartData(response.data?.data);
                    if ((router.asPath === `/${lang}/store/cart` || router.asPath === '/store/cart') && !response?.data?.data?.items?.length) {
                        navigate('/[lang]/store', '/store', lang);
                    }
                },
                () => {
                    setAlertData({
                        type: 'error', cont: true, default: 'error_delete_data'
                    });
                },
                userToken,
                lang,
                localization?.currency
            );
        }
    };
    return (
        <>
            {type === 'general'
                ?
                <Div styles={styles.cartWrapper}>
                    {products.length > 0 && products?.map((item, idx) =>
                        <CartItem
                            item={item}
                            key={`product${idx}`}
                            styles={styles}
                            updateDelivery={updateDelivery}
                            setUpdateDelivery={setUpdateDelivery}
                        />)}
                </Div>
                : type === 'with_packs'
                    ?
                    <Div styles={styles.cartWrapper}>
                        {userToken && (!currentUserPack?.id || currentUserPack?.id < lastPack?.id) &&
                            <PackProgress type='cart_widget'/>}
                        {products.length > 0 && products?.map((item, idx) =>
                            <CartItem
                                item={item}
                                key={`product${idx}`}
                                styles={styles}
                                updateDelivery={updateDelivery}
                                setUpdateDelivery={setUpdateDelivery}
                            />)}
                    </Div>
                    : type === 'widget' && products?.length > 0
                        ?
                        <Div styles={styles.cartWrapperWidget}>
                            {userToken && (!currentUserPack?.id || currentUserPack?.id < lastPack?.id) &&
                                <PackProgress type='store_widget' accordion={true}/>}
                            <Div styles={styles.cartWrapperWidgetInner}>
                                <Div styles={{ display: 'flex', 'flex-wrap': 'wrap' }}>
                                    <Div>
                                        <Div styles={styles.cartTitleWidget}>
                                            <T textName='store-inBasket' defaultStr='В корзине' page='/store'/>
                                            {' '}{products?.length}{' '}
                                            {declOfNum(products?.length, [
                                                <T key={'cartTitleWidget_1'} textName='store-inBasket-product'
                                                    defaultStr='товар' page='/store'/>,
                                                <T key={'cartTitleWidget_2'} textName='store-inBasket-product2'
                                                    defaultStr='товарa' page='/store'/>,
                                                <T key={'cartTitleWidget_3'} textName='store-inBasket-product3'
                                                    defaultStr='товаров' page='/store'/>
                                            ])}
                                        </Div>
                                        <Div styles={styles.flexRow}>
                                            <Div styles={styles.cartItemSubtitleWidget}><T textName='storeOnSums'
                                                defaultStr='На сумму'
                                                page='/store'/>:</Div>
                                            <Div styles={styles.totalPrice}>{totalProductsPrice || price}</Div>
                                            <Div styles={styles.totalMarketingPriceWidget}>{price_marketing}</Div>
                                        </Div>
                                    </Div>
                                    <Div styles={styles.cartItemWidget}>
                                        {
                                            products?.map((elem, idx) =>
                                                <CartItemWidget
                                                    styles={styles}
                                                    item={elem}
                                                    key={`cartItem${idx}`}
                                                />)
                                        }
                                    </Div>
                                </Div>
                                <Div
                                    styles={styles.buttonPrimarySmall}
                                    effects={{ hover: { ...styles.formButtonPrimaryHover } }}
                                    data-uitest='536_uitest' onClick={() => {
                                        if (currentUserPack?.id === 4 || currentUserPack?.id >= lastPack?.id || cartSumPlusAccumulated >= lastPack?.price || !userToken) {
                                            navigate('/[lang]/store/cart', '/store/cart', lang);
                                        } else {
                                            setModalCartShow(true);
                                        }
                                    }}
                                >
                                    <T textName='storeOrderCreate' defaultStr='Оформить заказ' page='/store'/>
                                </Div>
                            </Div>
                        </Div>
                        : type === 'hover-widget' && products?.length > 0
                            ?
                            <Div>
                                {userToken && (!currentUserPack?.id || currentUserPack?.id < lastPack?.id) &&
                                    <PackProgress type='cart_hover'/>}
                                <Div styles={styles.cartHeaderWidgetHover}>
                                    <Div styles={styles.cartTitleWidgetHover}>
                                        <T textName='store-inBasket' defaultStr='В корзине' page='/store'/>
                                        {' '}{products.length}{' '}
                                        {declOfNum(products.length, [
                                            <T key={'cartTitleWidget2_1'} textName='store-inBasket-product'
                                                defaultStr='товар' page='/store'/>,
                                            <T key={'cartTitleWidget2_2'} textName='store-inBasket-product2'
                                                defaultStr='товарa' page='/store'/>,
                                            <T key={'cartTitleWidget2_3'} textName='store-inBasket-product3'
                                                defaultStr='товаров' page='/store'/>
                                        ])}
                                    </Div>

                                    <Div
                                        styles={styles.cartDelete}
                                        data-uitest='537_uitest' onClick={() => deleteProducts()}
                                    ><T textName='storeClear' defaultStr='Очистить' page='/store'/></Div>
                                </Div>
                                <Div styles={styles.cartBodyWidgetHover}>
                                    {products?.map((item, idx) =>
                                        <CartItem
                                            item={item}
                                            key={`product${idx}`}
                                            styles={styles}
                                        />)}
                                </Div>
                                <Div>
                                    <Div styles={styles.cartFooterWidget}>
                                        <Div>
                                            <Div styles={styles.cartItemSubtitleWidgetHover}><T
                                                textName='storeTotalPrice'
                                                defaultStr='Всего на сумму'
                                                page='/store'/>:</Div>
                                            <Div styles={styles.flexRow}>
                                                <Div
                                                    styles={styles.totalPriceWidgetHover}>{totalProductsPrice || price}</Div>
                                                <Div styles={styles.totalMarketingPrice}>{price_marketing}</Div>
                                            </Div>
                                        </Div>
                                        <Div
                                            styles={styles.buttonPrimarySmall}
                                            effects={{ hover: { ...styles.formButtonPrimaryHover } }}
                                            data-uitest='538_uitest' onClick={() => {
                                                handleClearPrevPayment();
                                                if (currentUserPack?.id === 4 || currentUserPack?.id >= lastPack?.id || cartSumPlusAccumulated >= lastPack?.price || !userToken) {
                                                    navigate('/[lang]/store/cart', '/store/cart', lang);
                                                } else {
                                                    setModalCartShow(true);
                                                }
                                            }}
                                        >
                                            <T textName='storeOrderCreate' defaultStr='Оформить заказ' page='/store'/>
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                            : type === 'header-general'
                                ? <Div styles={styles.cartGrid}>
                                    <Div styles={{ ...styles.cartWrapper, ...styles.cartWrapperHeader }}>
                                        {products && products?.length > 0 && <Div styles={styles.cartWrapperHead}>
                                            <Div
                                                styles={{ ...styles.cartWrapperHeadItem, ...styles.cartWrapperHeadItemFirst }}><T
                                                    textName='storeProduct' defaultStr='Товар'
                                                    page={page || '/store'}/></Div>
                                            <Div styles={styles.cartWrapperHeadBlock}>
                                                <Div styles={styles.cartWrapperHeadItem}><T textName='storePrice'
                                                    defaultStr='Цена'
                                                    page={page || '/store'}/></Div>
                                                <Div styles={styles.cartWrapperHeadItem}><T textName='storeCount'
                                                    defaultStr='Кол-во'
                                                    page={page || '/store'}/></Div>
                                                <Div
                                                    styles={{ ...styles.cartWrapperHeadItem, ...styles.cartWrapperHeadItemPrices }}><T
                                                        textName='storeTotal' defaultStr='Итого'
                                                        page={page || '/store'}/></Div>
                                            </Div>
                                        </Div>}
                                        {products?.length > 0
                                            ? products?.map((item, idx) =>
                                                <CartItem
                                                    item={item}
                                                    key={`product${idx}`}
                                                    styles={styles}
                                                    price={true}
                                                    article={true}
                                                    funcs={funcs}
                                                    page={page}
                                                    domain={domain}
                                                />)
                                            : <Div styles={{ width: '100%', 'padding-top': '20px' }}><T
                                                textName='noProductText' defaultStr='Не добавлено ни одного товара'
                                                page='all'/>
                                            </Div>
                                        }
                                    </Div>
                                </Div>
                                : <Div></Div>}

        </>);
};

Cart.propTypes = {
    products: PropTypes.array,
    styles: PropTypes.object,
    type: PropTypes.oneOf([
        'general',
        'header-general',
        'hover-widget',
        'widget'
    ]),
    price: PropTypes.node,
    price_marketing: PropTypes.node,
    funcs: PropTypes.object,
    page: PropTypes.string
};
export default Styles(Cart, getStyles);